<template>
  <div class="member-layout">

    <validateForm ref="loginForm">
      <input-field v-model="email" :validate="rules.email" placeholder="이메일(아이디)" maxLength="100"/>
      <input-field v-model="password" :validate="rules.password" placeholder="비밀번호" type="password" maxLength="20" class="mt-10"/>
      <div v-if="msg" class="error-message">{{ msg }}</div>

      <div class="mt-15" @click="loginEmail">
        <button class="btn btn-x-large color-1 btn-block">로그인</button>
      </div>
    </validateForm>

    <div class="memberP-join-find-link">
      <div>
        <router-link to="/find">아이디 찾기</router-link>
        <span class="link-bar w-20"></span>
        <router-link to="/find">비밀번호 찾기</router-link>
      </div>
      <div>
        <router-link to="/join" class="join-link">회원가입</router-link>
      </div>
    </div>

    <div class="memberP-sns-login-box">
      <div @click="loginKakao" class="btn btn-x-large mb-10 btn-sns-login sns-kakao"><img src="@/assets/img/common/sns_kakao_symbol.svg"> 카카오로 3초 만에 시작하기</div>
<!--      <div class="box-title"><span>간편 로그인</span></div>-->

<!--      <div class="login-btn-box">-->
        <!-- <div @click="chkNaver('login')" class="btn btn-x-large mb-10 btn-sns-login sns-naver"><img src="@/assets/img/common/sns_naver_logo.png"> <span>간편 로그인</span></div> -->
<!--        <div @click="chkFacebook('login')" class="btn btn-x-large mb-10 btn-sns-login sns-facebook"><img src="@/assets/img/common/sns_facebook_logo.png"> <span>간편 로그인</span></div>-->
<!--        <div @click="chkKakao('login')" class="btn btn-x-large mb-10 btn-sns-login sns-kakao"><img src="@/assets/img/common/sns_kakao_symbol.svg"> 카카오로 3초 만에 시작하기</div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import snsMixin from "@/mixins/snsMixin"
export default {
  name: "login",
  mixins: [snsMixin],
  data() {
    return {
      email: '',
      password: '',
      rules: {
        email: [v => !!v || this.$msg('required.input')],
        password: [v => !!v || this.$msg('required.input')],
      },
      msg: null,
    }
  },
  created() {
    const snsCode = this.$route.query.code
    const snsState = this.$route.query.state
    if (snsCode && snsState) this.kakaoSync(snsCode, snsState).then()
  },
  methods: {
    loginKakao() {
      location.href = 'https://kauth.kakao.com/oauth/authorize?response_type=code' +
        '&client_id=' + this.$kakaoId +
        '&redirect_uri=' + encodeURIComponent(this.$kakaoCallback) +
        '&state=login' +
        '&encode_state=true'
    },
    async loginEmail() {
      this.msg = null
      if (this.$refs.loginForm.validate()) {
        const { result, data } = await this.$api.loginEmail({
          email: this.email,
          password: this.password
        })
        switch (result) {
          case 'success':
            const info = this.$parseJwt(data.token)
            if (info) {
              localStorage.setItem('auth', data.token)
              localStorage.setItem('id', info.id)
              localStorage.setItem('email', info.email)
              localStorage.setItem('name', info.name)
              localStorage.setItem('url', info.url ? info.url : '')
              localStorage.setItem('target', info.target)
              localStorage.setItem('phone', info.phone)
            }
            this.goPage()
            break;
          case 'sleep':
            localStorage.setItem('sleep', data.token)
            location.href = "/sleep"
            break;
          case 'no-exist':
            this.alertPopup(this.$msg('login.fail'))
            break;
          case 'kakao-existed':
            this.alertPopup(this.$msg('kakao-existed'))
            break;
          case 'fail':
            this.alertPopup(this.$msg('fail'))
            break;
          default:
            this.alertPopup(this.$msg('fail'))
        }
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'GO_LOGIN',
        okayCallback: () => {
          this.setMsgPopup()
        }
      })
    },
  }
}
</script>
